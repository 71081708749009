<template>
  <b-row id="spinner-indeterminate">
    <b-col>
      <div id="spinner-container">
        <svg class="spinner" width="52px" height="52px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
      <span>{{ this.text }}</span>
    </b-col>
  </b-row>
</template>

<script>

export default {
  name: 'spinner-indeterminate',
  components: {},
  props: {
    text: {
      type: String,
      default: 'Loading...'
    }
  }
}
</script>
<style lang="scss">
#spinner-indeterminate {
  font-size: 1.25em;
  font-weight: 400;
  color: $color-accent;

  > .col {
    text-align: center;
  }

  span {
    display: block;
    margin-top: 0.5rem;
  }

  #spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // Here is where the magic happens

  $offset: 187;
  $duration: 1.4s;

  .spinner {
    animation: rotator $duration linear infinite;
  }

  @keyframes rotator {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }

  .path {
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash $duration ease-in-out infinite,
    colors ($duration*4) ease-in-out infinite;
  }

  @keyframes colors {
    0% {
      stroke: $color-accent;
    }
    50% {
      stroke: $color-accent-hover;
    }
    100% {
      stroke: $color-accent;
    }
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: $offset;
    }
    50% {
      stroke-dashoffset: calc($offset / 4);
      transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: $offset;
      transform: rotate(450deg);
    }
  }
}
</style>
