<template>
  <b-breadcrumb :items="b_items"/>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'search-breadcrumb',
  computed: {
    ...mapState('search',{
      searchQuery: 'query',
      searchType: 'type'
    }),
    b_items: function () {
      const items = [{
        text: 'Search',
        to: '/'
      }]

      switch (this.$route.name) {
        case 'Results':
          items.push({
            text: 'Results'
          })
          break
        case 'Detail':
          if (this.searchQuery !== '') {
            items.push(
              {
                text: 'Results',
                to: '/search' + this.searchQuery
              },
              {
                text: this.$route.query.genus + ' ' + this.$route.query.species
              }
            )
          } else if (this.searchType === 'Spatial') {
            items.push(
              {
                text: 'Results',
                to: '/search'
              },
              {
                text: this.$route.query.genus + ' ' + this.$route.query.species
              }
            )
          } else {
            items.push(
              {
                text: this.$route.query.genus + ' ' + this.$route.query.species
              }
            )
          }
      }
      return items
    }
  },
  data () {
    return {}
  }
}
</script>
<style lang="scss" type="scss">
.breadcrumb {
  padding: 0;
  margin-bottom: 0;
  background-color: transparent;
}
</style>
