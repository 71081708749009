<template>
  <div id="quick-search">
    <b-form v-on:submit.prevent="search">
      <b-input-group size="lg">
        <template #prepend>
          <b-input-group-text :disabled="active" is-text>
            <font-awesome-icon icon="search" size="lg"></font-awesome-icon>
          </b-input-group-text>
        </template>
        <b-form-input id="searchbar"
                      ref="searchInput"
                      :disabled="active"
                      v-model="searchInput"
                      aria-label="Database search"
                      name="search"
                      placeholder="Type some species name..."
                      size="lg">
        </b-form-input>
      </b-input-group>
    </b-form>
  </div>
</template>
<script>

import router from '@/router/router'

export default {
  name: 'quick-search',
  props: {
    active: Boolean
  },
  data () {
    return {
      searchInput: ''
    }
  },
  methods: {
    search () {

      if (this.searchInput.length > 0) {
        let qString = '/search?' + new URLSearchParams('search=' + this.searchInput).toString()
        this.$store.commit('search/setType', 'text')
        router.push(qString)
      } else {
        // error ?
      }
    }
  }
}
</script>
<style lang="scss">
#quick-search {
  border-radius: 10px;
  background-color: white;
  box-shadow: 4px 10px 15px 0 $grey-disabled;

  #searchbar {
    font-weight: 300;
    color: $grey-light;
    height: 95px;
    border-radius: 0 10px 10px 0;
    border-left: none;
    border-color: $grey-disabled;

    &:focus {
      box-shadow: unset;
    }
  }

  .input-group-text {
    padding-left: 1.25em;
    border-right: none;
    border-radius: 10px 0 0 10px;
    color: $color-accent;
    background-color: unset;
    border-color: $grey-disabled;

    &[disabled] {
      background-color: $grey-disabled;
    }
  }
}
</style>
