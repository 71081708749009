<template>
  <b-container id="search-results">
    <search-breadcrumb></search-breadcrumb>
    <h3 v-if="type !== 'Spatial' && searchTerm.length > 0">Search results for: "{{ searchTerm }}"</h3>

    <b-row v-if="">
      <b-col align-self="center" class="my-1 pl-0" md="6">
        <span v-if="!isLoading" class="total-results">{{ totalResults }} records found</span>

      </b-col>
      <b-col class="my-1" md="2">
        <b-form-group class="mb-0" horizontal label="Per page">
          <b-form-select v-model="perPage" :options="pageOptions"/>
        </b-form-group>
      </b-col>
      <b-col class="my-1" md="4">
        <b-form-group class="mb-0" horizontal label="Sort" label-for="sortBySelect">
          <b-input-group>
            <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-75">
            </b-form-select>
            <b-form-select v-model="sortDesc" :disabled="!sortBy" class="w-25">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <spinner-indeterminate v-if="isLoading" :text="'...'"></spinner-indeterminate>
    <b-table :current-page="currPage"
             :fields="table_fields"
             :items="fetch"
             :per-page="perPage"
             sort-icon-left
             @row-clicked="onRowClicked"
    >
    </b-table>

    <b-row v-if="!isLoading">
      <b-col class="my-1" md="12">
        <b-pagination v-model="currPage" :per-page="perPage" :total-rows="totalResults" class="my-0"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import router from '@/router/router'
import SpinnerIndeterminate from '../base/SpinnerIndeterminate'
import { mapActions, mapState } from 'vuex'
import QuickSearch from '@/components/search/QuickSearch'
import SearchBreadcrumb from '@/components/search/SearchBreadcrumb'

export default {
  name: 'search-results',
  components: {
    SearchBreadcrumb,
    QuickSearch,
    SpinnerIndeterminate
  },
  data () {
    return {
      table_fields: [
        {
          key: 'score',
          sortable: true,
          sortDirection: 'desc',
          label: 'Relevance',
          class: 'field-hidden'
        },
        {
          key: 'genus',
          sortable: true,
          label: 'Genus'
        },
        {
          key: 'species',
          sortable: true,
          label: 'Species'
        },
        {
          key: 'author',
          sortable: true,
          formatter: 'authorFormatter',
          label: 'Author'
        },
        {
          key: 'year',
          sortable: true,
          label: 'Year'
        }
      ],
      items: [],
      queryString: '',
      filter: null,
      sortBy: 'score',
      sortDesc: true,
      //"unreal" page, starting with 1
      currPage: 1,
      perPage: 10,
      pageOptions: [10, 20, 50, 100]
    }
  },
  created () {
    this.queryString = new URLSearchParams(this.$route.query).toString()
  },
  computed: {
    ...mapState('search', {
      isLoading: 'isLoading',
      tableItems: 'results',
      searchType: 'type',
    }),
    ...mapState('search', ['type', 'results', 'totalResults', 'currentPage', 'totalPages']),
    // TODO: Fix searchTerm display for AdvSearch | store methods ?
    searchTerm () {
      const urlParams = new URLSearchParams(this.queryString)
      if (urlParams.has('search')) {
        return urlParams.get('search')
      }
      return ''
    },
    sortOptions () {
      // Create an options list from fields
      return this.table_fields
        .filter(f => f.sortable)
        .map(f => {
          return {
            text: f.label,
            value: f.key
          }
        })
    }
  },
  methods: {
    ...mapActions({
      fetchTextResults: 'search/fetchTextResults',
      fetchSpatialResults: 'search/fetchSpatialResults',
    }),
    async fetch () {
      //expects "unreal", human page
      let items = []
      if (this.searchType === 'text' || !this.searchType) {
        items = await this.fetchTextResults({
          query: this.queryString,
          page: this.currPage ? this.currPage - 1 : this.currentPage,
          size: this.perPage
        })
      } else if (this.searchType === 'spatial') {
        console.log(this.searchType)
        items = await this.fetchSpatialResults({
          page: this.currPage ? this.currPage - 1 : this.currentPage,
          size: this.perPage
        })
      }
      return items.content ?? []
    },
    onFiltered (filteredItems) {
      this.total_rows = filteredItems.length
      this.currPage = 1
    },
    onRowClicked: function (item) {
      const relUrl = `/species?genus=${item.genus}&species=${item.species}`
      this.$store.dispatch('search/saveSelectedId', item.id)
      router.push(relUrl)
    },
    authorFormatter: function (value, key, item) {
      if (item.parentheses === 'Y') {
        return '(' + value + ')'
      }
      return value
    }
  },
  beforeRouteLeave (to, from, next) {
    if (to.name.includes('search')) {
      next()
    } else {
      this.$store.commit('search/clearResults')
      this.$destroy()
      next()
    }
  }
}
</script>

<style lang="scss">
#search-results {

  .total-results {
    font-size: 1.25em;
  }

  > .row {
    margin: 2rem 0 1rem 0;
  }

  .container-fluid {
    margin-top: 25px;
  }

  .field-hidden {
    display: none;
  }

  thead {
    th {
      border-top: none;
    }
  }

  tbody {
    td {
      border-top: none;
      cursor: pointer;
    }

    tr:nth-child(odd) {
      background-color: ghostwhite;
    }

    tr:hover {
      background-color: rgba(0, 0, 0, 0.075);
    }
  }
}
</style>
